import { Route, Routes } from "react-router-dom";
import "./App.scss";
import { Homepage } from "./components/Homepage/Homepage";
import { Dashboard } from "./components/Dashboard/Dashboard";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/explore" element={<Dashboard />} />
      </Routes>
    </div>
  );
}

export default App;
