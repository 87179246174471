import { useEffect, useState } from "react";
import "./PlanCategory.scss";
import right from "../../../assets/right.svg";
import axios from "axios";

export const PlanCategory = () => {
  const [scrapingData, setAllScrapingData] = useState({});
  const [selectedID, setSelectedID] = useState("");

  // API CALL FOR GETTING ALL THE SCRAPING DATA HERE
  useEffect(() => {
    getAllScrapingDetails();
  }, []);

  const getAllScrapingDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/all/hosting/scrappingdetailswithcategories`
      );
      const data = response.data.data;
      setAllScrapingData(data);
      const firstNonEmptyCategory = Object.keys(data).find(
        (key) => data[key].length > 0
      );
      setSelectedID(firstNonEmptyCategory || "");
    } catch (error) {
      console.log(error);
    }
  };

  // MADE AN ARRAY FROM THE API DATA AND DESTRUCTING A KEYS FROM IT HERE
  const array = Object.keys(scrapingData).filter(
    (key) => scrapingData[key].length > 0
  );

  return (
    <section className="categoryTab">
      <ul className="nav nav-tabs">
        {array.map((category, index) => (
          <li
            className="nav-item"
            key={index}
            onClick={() => setSelectedID(category)}
          >
            <a
              className={`nav-link ${selectedID === category ? "active" : ""}`}
              data-bs-toggle="tab"
              href={`#${category}`}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </a>
          </li>
        ))}
      </ul>

      <div className="tab-content px-0">
        {array.map((category, index) => (
          <div
            className={`tab-pane container ${
              selectedID === category ? "active" : "fade"
            }`}
            id={category}
            key={index}
          >
            <div className="plateBox">
              {scrapingData[category].map((plan) => (
                <div className="planPlate" key={plan._id}>
                  <div className="profile">
                    <div className="logo">
                      <img src={plan.logo} alt={plan.name} />
                      <p className="mb-0">{plan.brand}</p>
                    </div>
                    <p className="mt-3">
                      Type: <span>{plan.server}</span>
                    </p>
                    <p>
                      Plan: <span>{plan.name}</span>
                    </p>
                  </div>
                  <div className="details">
                    <ul className="mb-0">
                      <li>Storage: {plan.storage}</li>
                      <li>Bandwidth: {plan.bandwidth}</li>
                      <li>SSL: {plan.freessl === "true" ? "Free" : "Paid"}</li>
                      <li>Backup: {plan.backup}</li>
                      {plan.email && <li>Email: {plan.email}</li>}
                    </ul>
                  </div>
                  <div className="price">
                    <div>
                      <h4>{plan.price}</h4>
                      <a
                        href={plan.redirectLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="btn btn-grey">
                          Check the offer <img src={right} alt="right arrow" />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
