/* eslint-disable */
import React, { useEffect, useState } from "react";
import "./DataComparison.scss";
import right from "../../../assets/right.svg";
import axios from "axios";

export const DataComparison = () => {
  const [scrapingData, setAllScrapingData] = useState([]);
  const [selectedNames, setSelectedNames] = useState([
    "Essential",
    "Premium",
    "Pro",
  ]);

  const [selectedBrands, setSelectedBrands] = useState([
    { brand: "bigrock", server: "cloudhosting", name: "Starter SSD" },
    { brand: "bigrock", server: "cloudhosting", name: "Advanced SSD" },
    { brand: "bigrock", server: "cloudhosting", name: "Business SSD" },
  ]);

  // SETTING THE INITIAL DATA FROM API HERE
  useEffect(() => {
    if (scrapingData.length > 0) {
      const initialSelectedBrands = [
        {
          ...scrapingData.find(
            (data) =>
              data?.brand === "bigrock" &&
              data?.server === "cloudhosting" &&
              data?.name === "Starter SSD"
          ),
        },
        {
          ...scrapingData.find(
            (data) =>
              data?.brand === "bigrock" &&
              data?.server === "cloudhosting" &&
              data?.name === "Advanced SSD"
          ),
        },
        {
          ...scrapingData.find(
            (data) =>
              data?.brand === "bigrock" &&
              data?.server === "cloudhosting" &&
              data?.name === "Business SSD"
          ),
        },
      ];
      setSelectedBrands(initialSelectedBrands);
    }
  }, [scrapingData]);

  // API CALL FOR ALL THE SCRAPING DETAILS
  useEffect(() => {
    getAllScrapingDetails();
  }, []);

  const getAllScrapingDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/all/hosting/scrappingdetails`
      );
      setAllScrapingData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // FOR HANDLING BRAND WHENEVER WE CHANGE THE BRAND FROM THE DROPDOWN
  const handleBrandChange = (index, brand) => {
    const newSelectedBrands = [...selectedBrands];
    newSelectedBrands[index] = {
      brand,
      server: "",
      name: "",
    };
    setSelectedBrands(newSelectedBrands);
    setSelectedNames((prevNames) => {
      const updatedNames = [...prevNames];
      updatedNames[index] = "";
      return updatedNames;
    });
  };

  // FOR HANDLING SERVER WHENEVER WE CHANGE THE SERVER FROM THE DROPDOWN
  const handleServerChange = (index, server) => {
    const newSelectedBrands = [...selectedBrands];
    newSelectedBrands[index] = {
      ...newSelectedBrands[index],
      server,
      name: "",
    };
    setSelectedBrands(newSelectedBrands);
    setSelectedNames((prevNames) => {
      const updatedNames = [...prevNames];
      updatedNames[index] = "";
      return updatedNames;
    });
  };

  // FOR HANDLING NAME WHENEVER WE CHANGE THE NAME FROM THE DROPDOWN
  const handleNameChange = (index, name) => {
    setSelectedNames((prevNames) => {
      const updatedNames = [...prevNames];
      updatedNames[index] = name;
      return updatedNames;
    });

    const selectedBrand = scrapingData.find(
      (brand) =>
        brand.brand === selectedBrands[index].brand &&
        brand.server === selectedBrands[index].server &&
        brand.name === name
    );

    const newSelectedBrands = [...selectedBrands];
    newSelectedBrands[index] = {
      ...selectedBrand,
      name,
    };
    setSelectedBrands(newSelectedBrands);
  };

  // FUNCTION USED HERE FOR SHOWING THE DROPDOWNS
  const renderDropdown = (index, type) => {
    let items = [];
    if (type === "brand") {
      items = [...new Set(scrapingData.map((brand) => brand.brand))];
    } else if (type === "server") {
      items = [
        ...new Set(
          scrapingData
            .filter((brand) => brand.brand === selectedBrands[index].brand)
            .map((brand) => brand.server)
        ),
      ];
    } else if (type === "name") {
      items = [
        ...new Set(
          scrapingData
            .filter(
              (brand) =>
                brand.brand === selectedBrands[index].brand &&
                brand.server === selectedBrands[index].server
            )
            .map((brand) => brand.name)
        ),
      ];
    }

    return (
      <div className="dropdown">
        <button
          className="btn btn-light dropdown-toggle mb-2"
          type="button"
          id={`dropdownMenuButton${index}-${type}`}
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {selectedBrands[index][type] ? (
            <>
              {type === "brand" && (
                <img
                  src={
                    scrapingData.find(
                      (brand) => brand.brand === selectedBrands[index].brand
                    )?.logo
                  }
                  alt={selectedBrands[index].brand}
                  className="brand-logo me-3"
                  width="30"
                  height="30"
                />
              )}
              {selectedBrands[index][type]}
            </>
          ) : (
            `Select ${type.charAt(0).toUpperCase() + type.slice(1)}`
          )}
        </button>
        <ul
          className="dropdown-menu"
          aria-labelledby={`dropdownMenuButton${index}-${type}`}
        >
          {items.map((item, idx) => (
            <li key={idx}>
              <a
                className="dropdown-item"
                onClick={() => {
                  if (type === "brand") {
                    handleBrandChange(index, item);
                  } else if (type === "server") {
                    handleServerChange(index, item);
                  } else if (type === "name") {
                    handleNameChange(index, item);
                  }
                }}
              >
                {type === "brand" && (
                  <img
                    src={
                      scrapingData.find((brand) => brand.brand === item)?.logo
                    }
                    alt={item}
                    className="brand-logo me-3"
                    width="30"
                    height="30"
                  />
                )}
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <section className="compare">
      <div className="container py-5 text-center px-0">
        <h3 className="pb-4 text-start">Compare to choose the best plan</h3>
        <div className="table-responsive">
          <table className="table table-bordered ">
            <thead>
              <tr>
                <th scope="col"></th>
                {selectedBrands.map((_, index) => (
                  <th scope="col" key={index}>
                    {renderDropdown(index, "brand")}
                    {selectedBrands[index].brand &&
                      renderDropdown(index, "server")}
                    {selectedBrands[index].brand &&
                      selectedBrands[index].server &&
                      renderDropdown(index, "name")}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name</td>
                {selectedNames.map((name, index) => (
                  <td key={index}>{name}</td>
                ))}
              </tr>
              {[
                "price",
                "storage",
                "websites",
                "freessl",
                "freeDomain",
                "bandwidth",
                "backup",
              ].map((field, idx) => (
                <tr key={idx}>
                  <td>{field.charAt(0).toUpperCase() + field.slice(1)}</td>
                  {selectedBrands.map((selected, index) => (
                    <td key={index}>
                      {field === "freessl" && selected[field] === "true"
                        ? "Unlimited Free SSL"
                        : "-" &&
                          field === "freeDomain" &&
                          selected[field] === "true"
                          ? "Free Domain"
                          : ("-" && selected[field] === "false") ||
                            selected[field] === ""
                            ? "-"
                            : selected[field]}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <th></th>
                {selectedBrands.map((selected, index) => (
                  <th key={index}>
                    {selected.redirectLink ? (
                      <a
                        className="btn btn-theme d-block mx-auto"
                        href={selected.redirectLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Check the offer <img src={right} alt="" />
                      </a>
                    ) : (
                      <button className="btn btn-theme d-block mx-auto" disabled>
                        Check the offer <img src={right} alt="" />
                      </button>
                    )}
                  </th>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};