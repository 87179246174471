import { Link } from "react-router-dom";
import logo from "../../../assets/logo.svg";
import Home from "../../../assets/home.svg";
import toggleIcon from "../../../assets/toggle.svg";

import "./Header.scss";
export const Header = ({ toggle }) => {
    return <header className="header mb-0">
        <div className="headBar">
            <div className="Brand">
                <div className="toggle" onClick={toggle}>
                    <img src={toggleIcon} alt="" />
                </div>
                <div className="logo">
                    <Link to="/"><img src={logo} alt="" /></Link>
                </div>
            </div>
            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse " id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/#process">Process</Link>
                                    </li><li className="nav-item">
                                        <Link className="nav-link" to="/#about_us">About Us</Link>
                                    </li><li className="nav-item">
                                        <Link className="nav-link" to="/#contact_us">Contact Us</Link>
                                    </li>

                                </ul>
                            </div> */}
            <div className="Gotohome">
                <Link to="/home"><img src={Home} alt="" /></Link>
            </div>
        </div>
    </header>
}
