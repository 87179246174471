
import { SearchResults } from "./SearchResults/SearchResults"
import { Header } from "./Header/Header"
import { Sidebar } from "./Sidebar/Sidebar"
import { useState } from "react";

export const Dashboard = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const isToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    }

    return <>
        <Header toggle={toggleSidebar} />
        <Sidebar isSidebarOpen={isSidebarOpen} isToggle={isToggle} />
        <SearchResults />
    </>
}