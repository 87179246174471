const filterReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, isLoading: true };
    case "GET_DATA":
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        filteredData: action.payload,
      };
    case "GET_BRAND_DATA":
      return { ...state, brandData: action.payload };
    case "API_ERROR":
      return { ...state, isLoading: false, isError: true };
    case "SET_FILTERS":
      return { ...state, filters: action.payload };
    case "APPLY_FILTERS":
      return { ...state, filteredData: action.payload };

    case "CLEAR_FILTERS":
      return {
        ...state,
        filters: {
          freeDomain: false,
          freessl: false,
          server: "",
          email: "",
          brand: [],
          price: "1-0",
          storage: "1-0",
          websites: "1-0",
        },
        filteredData: state.data, // Reset filtered data to initial data
      };
    default:
      return state;
  }
};

export default filterReducer;
