import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFilterContext } from "../../../context/filterContext";
import { PlanCategory } from "../PlanCategory/PlanCategory";
import { BrandsCategory } from "../BrandsCategory/BrandsCategory";
import { DataComparison } from "../DataComparison/DataComparison";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import styles
import "swiper/css";
import "swiper/css/navigation";
import "./SearchResults.scss";

export const SearchResults = () => {
  const { data, isLoading } = useFilterContext();
  const [obj, setObj] = useState({});

  // BreakPoint setting to change the number of cards per breakpoints
  const breakpointSettings = {
    200: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
    440: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
    680: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
  };

  useEffect(() => {
    if (!data || !data.data) {
      // Handle case where data or data.data is not yet available
      return;
    }

    const transformedData = data.data.reduce((acc, item) => {
      if (!item || !item.server) {
        return acc; // Skip undefined or null items, or items without server property
      }

      const { server } = item;

      let category;

      if (server.includes("cloud")) {
        category = "cloud hosting";
      } else if (server.includes("woocommerce-hosting")) {
        category = "woocommerce hosting";
      } else if (server.includes("wordpress-hosting")) {
        category = "wordpress hosting";
      } else if (server.includes("web-hosting")) {
        category = "web hosting";
      } else if (server.includes("windows-hosting")) {
        category = "windows hosting";
      } else if (server.includes("static-hosting")) {
        category = "static hosting";
      } else if (server.includes("vps-hosting")) {
        category = "VPS hosting";
      } else if (server.includes("linux-hosting")) {
        category = "linux hosting";
      } else if (server.includes("reseller-hosting")) {
        category = "reseller hosting";
      } else if (server.includes("ecommerce-hosting")) {
        category = "ecommerce hosting";
      } else if (server.includes("dedicated")) {
        category = "dedicated server";
      } else if (server.includes("shared")) {
        category = "shared";
      } else if (server.includes("Apache")) {
        category = "Apache";
      } else {
        category = "Other hostings";
      }

      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    }, {});

    delete transformedData["undefined"];

    if (transformedData["Other hostings"]) {
      const otherHostings = transformedData["Other hostings"];
      delete transformedData["Other hostings"];
      transformedData["Other hostings"] = otherHostings;
    }

    const orderedKeys = [
      "web hosting",
      "VPS hosting",
      "reseller hosting",
      "wordpress hosting",
      "cloud hosting",
      "dedicated server",
      "ecommerce hosting",
      "shared",
      "windows hosting",
      "woocommerce hosting",
      "static hosting",
      "linux hosting",
      "Apache",
      "Other hostings"
    ];

    const orderedTransformedData = {};
    orderedKeys.forEach(key => {
      if (transformedData[key]) {
        orderedTransformedData[key] = transformedData[key];
      }
    });

    setObj(orderedTransformedData);
  }, [data]);


  return (
    <section className="resultBox">
      <div className="container-fluid">
        <div className="row">
          {isLoading ? (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ minHeight: "50vh" }}
            >
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            </div>
          ) : (
            <div className="col-12 m-auto">
              <div className="row resultHeight">
                {data?.data?.length === 0 ? (
                  <iframe
                    src="https://lottie.host/embed/1040a576-5627-4cfa-9b7a-6ea7bd1ec69f/aJ7pAa3Dzc.json"
                    height={500}
                    className="rounded"
                    title="404"
                  ></iframe>
                ) : (
                  <>
                    {obj &&
                      Object?.keys(obj)?.map((category, index) => (
                        <div key={index} className="col-12">
                          <div className="row">
                            <div className="col-12 heading">
                              <h5 className="mt-3 mb-0 text-capitalize">
                                {category} ({obj[category]?.length})
                              </h5>
                            </div>
                            <div className="col-12">
                              <Swiper
                                spaceBetween={30}
                                slidesPerView={3}
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                breakpoints={breakpointSettings}
                                navigation
                              >
                                {obj[category].map((currentData, dataIndex) => {
                                  const {
                                    brand,
                                    email,
                                    freeDomain,
                                    freessl,
                                    name,
                                    websites,
                                    price,
                                    server,
                                    storage,
                                    logo,
                                    redirectLink,
                                  } = currentData;



                                  return (
                                    <SwiperSlide key={dataIndex} className="mt-0">
                                      <div className="col-lg-12 mt-3">
                                        <div className="searchResult">
                                          <div className="row">
                                            <div className="col-12">
                                              <div className="companyName">
                                                <img
                                                  src={logo}
                                                  alt="company logo"
                                                  className={`companyLogo ${brand === "ionos"
                                                    ? "ionos-img"
                                                    : ""
                                                    }`}
                                                />
                                                <div className="name">
                                                  {brand}
                                                </div>
                                              </div>
                                              <hr />
                                            </div>
                                            <div className="col-12">
                                              <div className="planDetails">
                                                <div className="name">
                                                  <h4>
                                                    Type: <span>{server}</span>
                                                  </h4>
                                                  <h4>
                                                    Plan: <span>{name}</span>
                                                  </h4>
                                                </div>
                                                <ul className="ps-0">
                                                  {websites && (
                                                    <li>
                                                      {/(website|websites|website \(s\))\b/i.test(
                                                        websites
                                                      )
                                                        ? websites
                                                        : `${websites} Websites`}
                                                    </li>
                                                  )}

                                                  {storage && (
                                                    <li>
                                                      {storage
                                                        .replace(/&nbsp;/g, " ")
                                                        .includes("Storage")
                                                        ? storage
                                                        : `${storage.replace(
                                                          /&nbsp;/g,
                                                          " "
                                                        )} Storage`}
                                                    </li>
                                                  )}

                                                  {freessl && (
                                                    <li>Unlimited Free SSL</li>
                                                  )}
                                                  {freeDomain && (
                                                    <li>Free Domain</li>
                                                  )}
                                                  <li>{email} Email</li>
                                                </ul>
                                                <div className="price mt-2">
                                                  <h5 className="mb-0">
                                                    {price}
                                                  </h5>
                                                  <Link
                                                    to={redirectLink}
                                                    target="_blank"
                                                    className="card-link"
                                                  ><button className="btn btn-theme">
                                                      Check offer
                                                    </button>
                                                  </Link>

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  );
                                })}
                              </Swiper>
                            </div>
                          </div>


                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
          )}

          <div className="col-12">
            <div className="row">
              <div className="col-12 mx-auto mt-4">
                <PlanCategory />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mx-auto mt-4">
                <BrandsCategory />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mx-auto mt-4">
                <DataComparison />
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
};
