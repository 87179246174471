import React from 'react';
import { useFilterContext } from '../../../context/filterContext';

const Email = () => {
  const { filters, emailFilter } = useFilterContext();

  const handleEmailoptionChange = (e) => {
    emailFilter(e.target.value);
  };

  return (
    <>
      <div className="sliderBox mb-0">
        <p className='mb-2'>Email</p>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            value="1"
            checked={filters.email === "1"}
            onChange={handleEmailoptionChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio1">
            1 Email
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio2"
            value="100"
            checked={filters.email === "100"}
            onChange={handleEmailoptionChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio2">
            100 Email
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio3"
            value="Unlimited"
            checked={filters.email === "Unlimited"}
            onChange={handleEmailoptionChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio3">
            Unlimited Email
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio4"
            value="Free"
            checked={filters.email === "Free"}
            onChange={handleEmailoptionChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio4">
            Free Email
          </label>
        </div>
      </div>
    </>
  );
};

export default Email;
