import React, { useState, useEffect } from "react";
import { useFilterContext } from "../../../context/filterContext";

const Brands = () => {
  const [checkedBrands, setCheckedBrands] = useState([]);
  const { applyFilters, filters, brandData } = useFilterContext();


  // Effect to reset checked brands when filters are cleared
  useEffect(() => {
    if (!filters.brand || filters.brand.length === 0) {
      setCheckedBrands([]);
    }
  }, [filters]);
  const handleBrandFilter = (event) => {
    const { value, checked } = event.target;
    const updatedCheckedBrands = checked
      ? [...checkedBrands, value]
      : checkedBrands.filter((brand) => brand !== value);

    setCheckedBrands(updatedCheckedBrands);

    const updatedFilters = {
      ...filters,
      brand: updatedCheckedBrands,
    };
    applyFilters(updatedFilters);

  };

  return (
    <>
      <div className="sliderBox">
        <p>Brand</p>
        <div className="brands">
          <div className="brandList">
            {brandData?.data?.map((currentData, index) => {
              return (
                <div
                  className="d-flex justify-content-between align-items-center my-3"
                  key={index}
                >
                  <div className="brandName">
                    <img
                      src={currentData.logo}
                      alt=""
                      className={`${currentData.name === "ionos"
                          ? "ionos-sidebar-img"
                          : ""
                        }`}
                    />
                    <div className="mb-0 text-capitalize">{currentData.name}</div>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={currentData.name}
                      checked={checkedBrands.includes(currentData.name)}
                      id={`flexCheck${index}`}
                      onChange={handleBrandFilter}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
