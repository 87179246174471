import React from "react";
import { useFilterContext } from "../../../context/filterContext";

const Server = () => {
  const { filters, applyFilters } = useFilterContext();

  const handleFilterChange = (event) => {
    const selectedServer = event.target.value;

    const updatedFilters = {
      ...filters,
      server: selectedServer,
    };
    applyFilters(updatedFilters);
  };

  return (
    <>
      <div className="sliderBox">
        <p>Server</p>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio1"
            value="shared"
            checked={filters.server === "shared"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio1">
            Shared
          </label>
        </div>

        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio2"
            value="dedicated"
            checked={filters.server === "dedicated"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio2">
            Dedicated
          </label>
        </div>


        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio3"
            value="cloud"
            checked={filters.server === "cloud"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio3">
            Cloud Hosting
          </label>
        </div>



        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio4"
            value="web"
            checked={filters.server === "web"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio4">
            Web Hosting
          </label>
        </div>



        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio5"
            value="window"
            checked={filters.server === "window"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio5">
            Windows Hosting
          </label>
        </div>



        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio6"
            value="wordpress"
            checked={filters.server === "wordpress"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio6">
            Wordpress Hosting
          </label>
        </div>



        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio7"
            value="static"
            checked={filters.server === "static"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio7">
            Static Hosting
          </label>
        </div>


        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio8"
            value="vps"
            checked={filters.server === "vps"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio8">
            VPS Hosting
          </label>
        </div>

        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio9"
            value="woocommerce"
            checked={filters.server === "woocommerce"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio9">
            Woocommerce Hosting
          </label>
        </div>


        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio10"
            value="linux"
            checked={filters.server === "linux"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio10">
            Linux Hosting
          </label>
        </div>


        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio11"
            value="reseller"
            checked={filters.server === "reseller"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio11">
            Reseller Hosting
          </label>
        </div>



        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio12"
            value="ecommerce"
            checked={filters.server === "ecommerce"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio12">
            E-Commerce Hosting
          </label>
        </div>


        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="serverOptions"
            id="inlineRadio13"
            value="Apache"
            checked={filters.server === "Apache"}
            onChange={handleFilterChange}
          />
          <label className="form-check-label" htmlFor="inlineRadio13">
            Apache Hosting
          </label>
        </div>

      </div>
    </>
  );
};

export default Server;
