import "./BrandsCategory.scss";
import right from "../../../assets/right.svg";
import { useEffect, useState } from "react";
import axios from "axios";

export const BrandsCategory = () => {
  const [brands, setBrands] = useState([]);
  const [scrapingData, setAllScrapingData] = useState([]);
  const [servers, setServers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [cardData, setCarddData] = useState([]);
  const [selectedServer, setSelectedServer] = useState("");

  useEffect(() => {
    getAllBrands();
    getAllScrapingDetails();
  }, []);

  useEffect(() => {
    if (brands.length > 0) {
      handleOnclick(brands[0]?.name);
    }
  }, [brands, scrapingData]);

  // API CALL FOR GETTING ALL THE BRANDS
  const getAllBrands = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/all/brands`)
      .then((response) => {
        setBrands(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // API CALL FOR GETTING ALL THE SCRAPING DATA HERE
  const getAllScrapingDetails = async () => {
    await axios
      .get((`${process.env.REACT_APP_BASE_URL}/api/all/hosting/scrappingdetails`))
      .then((response) => {
        setAllScrapingData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // ON CLICK OF BRAND OPENING AND SETTING THE CARD DATA HERE
  const handleOnclick = (specificBrand) => {
    setSelectedServer("");
    const filterData = scrapingData?.filter(
      (scrapItems) => scrapItems?.brand === specificBrand
    );

    setFilteredData(filterData);

    const SpecificServers = filterData?.map((data) => {
      return {
        server: data?.server,
      };
    });

    setSelectedServer(SpecificServers[0]?.server);

    const data = filterData?.filter(
      (items) => items?.server === SpecificServers[0]?.server
    );
    setCarddData(data);
    const uniqueServers = Array?.from(
      new Set(SpecificServers?.map((item) => item?.server))
    );
    const uniqueServerArray = uniqueServers?.map((server) => ({ server }));
    setServers(uniqueServerArray);
  };

  // BASED ON SELECTED SERVER TYPE SETTING THE CARD'S DATA HERE
  const handleOnSpecificServer = (selectedServer) => {
    setSelectedServer(selectedServer);
    const filteredCardsData = filteredData?.filter(
      (filterData) => filterData?.server === selectedServer
    );
    setCarddData(filteredCardsData);
  };

  // FOR HANDLE LINKS IN NEW TAB
  const handleOffer = (link) => {
    window.open(link, "_blank");
  };

  return (
    <section className="brandtabs">
      <h3>Brands</h3>
      <div className="branddata">
        <ul className="nav nav-tabs">
          {brands && brands?.map((brand, index) => (
            <li
              key={index}
              className={`nav-item ${index === 0 ? "active" : ""}`}
              onClick={() => handleOnclick(brand.name)}
            >
              <a className={`nav-link ${index === 0 ? "active" : ""}`} data-bs-toggle="tab" href="#home">
                <img src={brand?.logo} alt="brands" />
                <p className="text-capitalize">{brand?.name}</p>
              </a>
            </li>
          ))}
        </ul>

        <div className="tab-content">
          <div className="tab-pane container fade show active" id="home">
            <div className="row">
              {servers?.length !== 0 ? (
                <div className="col-12">
                  <div className="dropdown">
                    <button
                      className="btn btn-light dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedServer === "" ? "Select Brand" : selectedServer}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {servers?.map((selectServer, index) => (
                        <li
                          key={index}
                          onClick={() =>
                            handleOnSpecificServer(selectServer?.server)
                          }
                        >
                          <a className="dropdown-item">{selectServer?.server}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}

              <div className="col-12 mt-4">
                <div className="resultcontainer">
                  {cardData.length !== 0 ?
                    cardData.map((data, index) => (
                      <div className="searchResult" key={index}>
                        <div className="companyName">
                          <img src={data?.logo} alt="company logo" />
                          <div className="name">{data?.brand}</div>
                        </div>
                        <div className="Details">
                          <div className="name">
                            <h4 className="my-2">
                              Type: <span>{data?.server}</span>
                            </h4>
                            <h4 className="my-2">
                              Plan: <span> {data?.name}</span>
                            </h4>
                          </div>
                          <ul className="">
                            {data?.websites ? <li>{data?.websites}</li> : ""}
                            {data?.storage ? <li>{data?.storage}</li> : ""}
                            {data?.freeSsl ? <li>{"Unlimited Free SSL"}</li> : ""}
                            {data?.freeDomain ? <li>{"Free Domain"}</li> : ""}
                            {data?.bandwidth ? <li>{data?.bandwidth}</li> : ""}
                            {data?.backup ? <li>{data?.backup}</li> : ""}
                            {data?.email ? <li>{data?.email}</li> : ""}
                          </ul>
                          <div className="price mt-4">
                            <h4>{data?.price}</h4>
                            <button
                              className="btn btn-grey"
                              onClick={() => handleOffer(data?.redirectLink)}
                            >
                              Check the offer <img src={right} alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                    : (
                      <div className="text-center">
                        No Data Available
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
