import React from "react";
import { useFilterContext } from "../../../context/filterContext"

const Ssl = () => {
  const { filters, applyFilters } = useFilterContext();

  const handleFreeSSLCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedFilters = {
      ...filters,
      freessl: checked,
    };
    applyFilters(updatedFilters);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="brandName">
          <span style={{ fontWeight: 500 }}>Free SSL</span>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={filters.freessl}
            id="freessl"
            onChange={handleFreeSSLCheckboxChange}
          />
        </div>
      </div>
    </>
  );
};

export default Ssl;
