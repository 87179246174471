import { createContext, useContext, useReducer, useEffect } from "react";
import axios from "axios";
import reducer from "../reducer/filterReducer";

const AppContext = createContext();

const URL = `${process.env.REACT_APP_BASE_URL}/api/all/hosting/scrappingdetails`;
const brandUrl = `${process.env.REACT_APP_BASE_URL}/api/all/brands`;

const AppProvider = ({ children }) => {
  const initialState = {
    isLoading: false,
    isError: false,
    data: [],
    filteredData: [],
    brandData: [],
    filters: {
      server: "",
      freeDomain: "",
      freessl: "",
      email: "",
      brand: [],
      price: "1-0",
      storage: "1-0",
      websites: "1-0",
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await axios.get(url);
      const filterData = response?.data;

      dispatch({ type: "GET_DATA", payload: filterData });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };
  const fetchBrandData = async (url) => {
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await axios.get(url);
      const brandData = response?.data;

      dispatch({ type: "GET_BRAND_DATA", payload: brandData });
    } catch (error) {
      dispatch({ type: "API_ERROR" });
    }
  };
  const clearFilters = () => {
    dispatch({ type: "CLEAR_FILTERS" });
    getData(URL);
  };
  const applyFilters = (updatedFilters) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // This makes the scroll smooth
    });
    dispatch({ type: "SET_FILTERS", payload: updatedFilters });
    dispatch({ type: "APPLY_FILTERS" });
  };
  const emailFilter = (email) => {
    const updatedFilters = { ...state.filters, email };
    applyFilters(updatedFilters);
  };
  //freessl=&freeDomain=&server=&price=&storage=&email=&websites=&brand=";

  useEffect(() => {
    const finalUrl = buildUrl(URL, state.filters);
    fetchBrandData(brandUrl);
    getData(finalUrl);
  }, [state.filters]);

  const buildUrl = (baseUrl, filters) => {
    let queryParams = [];

    for (const key in filters) {
      if (
        filters[key] !== undefined &&
        filters[key] !== "" &&
        filters[key] !== false &&
        filters[key] !== "0-0" &&
        filters[key] !== "1-0"
      ) {
        if (Array.isArray(filters[key])) {
          if (filters[key].length > 0) {
            queryParams.push(
              `${encodeURIComponent(key)}=${encodeURIComponent(
                JSON.stringify(filters[key])
              )}`
            );
          }
        } else {
          queryParams.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`
          );
        }
      }
    }
    // const decodedUrl = decodeURIComponent(queryParams);
    return `${baseUrl}?${queryParams.join("&")}`;
  };

  useEffect(() => {
    const {
      freessl,
      freeDomain,
      server,
      email,
      brand,
      price,
      storage,
      websites,
    } = state.filters;
    let filteredData = [{ ...state.data }];
    if (freessl) {
      filteredData = filteredData.filter((item) => item.freessl);
    }

    if (freeDomain) {
      filteredData = filteredData.filter((item) => item.freeDomain);
    }

    if (server) {
      filteredData = filteredData.filter((item) => item.server === server);
    }

    if (email) {
      filteredData = filteredData.filter((item) => item.email === email);
    }
    if (brand.length > 0) {
      filteredData = filteredData.filter((item) => brand.includes(item.brand));
    }
    if (price) {
      filteredData = filteredData.filter((item) => item.price === price);
    }

    if (storage) {
      filteredData = filteredData.filter((item) => item.storage === storage);
    }

    if (websites) {
      filteredData = filteredData.filter((item) => item.websites === websites);
    }
    dispatch({ type: "APPLY_FILTERS", payload: filteredData });
  }, [state.filters, state.data]);

  return (
    <AppContext.Provider
      value={{ ...state, applyFilters, emailFilter, clearFilters }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom context hook
const useFilterContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext, useFilterContext };
