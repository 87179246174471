import React from "react";
import { useFilterContext } from "../../../context/filterContext";

const Domain = () => {
  const { filters, applyFilters } = useFilterContext();

  const handleCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedFilters = {
      ...filters,
      freeDomain: checked,
    };
    applyFilters(updatedFilters);
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="brandName">
          <span style={{ fontWeight: 500 }}>Free Domain</span>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={filters.freeDomain}
            id="freeDomain"
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
    </>
  );
};

export default Domain;
