import { useState } from "react";
import "./Sidebar.scss";
import Brands from "./brands";
import Server from "./server";
import Email from "./email";
import { useFilterContext } from "../../../context/filterContext";
import Ssl from "./ssl";
import Domain from "./domain";
import closeIcon from "../../../assets/close_icon.svg";

export const Sidebar = ({ isSidebarOpen, isToggle }) => {
  const { filters, applyFilters, clearFilters } = useFilterContext();
  const [priceFilter, setPriceFilter] = useState(filters.price.split("-")[1]);
  const [websiteFilter, setWebsiteFilter] = useState(filters.websites.split("-")[1]);
  const [storageFilter, setStorageFilter] = useState(filters.storage.split("-")[1]);

  const handleClear = () => {
    clearFilters();
    setPriceFilter(0);
    setWebsiteFilter(0);
    setStorageFilter(0);
  };

  const handleRangeChange = (e, filterType) => {
    const value = Number(e.target.value);
    if (filterType === 'price') {
      setPriceFilter(value);
    } else if (filterType === 'storage') {
      setStorageFilter(value);
    } else if (filterType === 'websites') {
      setWebsiteFilter(value);
    }
  };

  const handleRangeApply = (filterType) => {
    let updatedFilters = { ...filters };
    if (filterType === 'price') {
      updatedFilters.price = `1-${priceFilter}`;
    } else if (filterType === 'storage') {
      updatedFilters.storage = `1-${storageFilter}`;
    } else if (filterType === 'websites') {
      updatedFilters.websites = `1-${websiteFilter}`;
    }
    applyFilters(updatedFilters);
  };

  return (
    <aside className={isSidebarOpen ? "filterBox toggle" : "filterBox"}>
      <div className="title d-flex align-items-center justify-content-between mb-3">
        <button
          type="button"
          className="btn"
          style={{ background: "#E7873B", color: "white", borderRadius: '10px' }}
          onClick={handleClear}
        >
          Clear
        </button>
        <div className="filter">Filters</div>
        <div className="d-sm-none d-block" onClick={isToggle}>
          <img src={closeIcon} alt="" width={20} />
        </div>

      </div>
      <div className="filterScroll pe-2 m-2">
        <div className="sliderBox mt-0">
          <div className="d-flex align-items-center justify-content-between">
            <p className="mb-0">Price</p>
            {priceFilter !== 0 && <p className="mb-0">$1 - ${priceFilter}</p>}
          </div>

          <input
            type="range"
            min="0"
            max="100"
            value={priceFilter}
            onChange={(e) => handleRangeChange(e, 'price')}
            onMouseUp={() => handleRangeApply('price')}
            onTouchEnd={() => handleRangeApply('price')}
          />
        </div>
        <div className="sliderBox">
          <div className="d-flex align-items-center justify-content-between">
            <p className="mb-0">Storage</p>
            {storageFilter !== 0 && <p className="mb-0">1GB - {storageFilter}GB</p>}
          </div>
          <input
            type="range"
            min="0"
            max="100"
            value={storageFilter}
            onChange={(e) => handleRangeChange(e, 'storage')}
            onMouseUp={() => handleRangeApply('storage')}
            onTouchEnd={() => handleRangeApply('storage')}
          />
        </div>
        <div className="sliderBox">
          <div className="d-flex align-items-center justify-content-between">
            <p className="mb-0">Websites</p>
            {websiteFilter !== 0 && <p className="mb-0">1 - {websiteFilter}</p>}
          </div>
          <input
            type="range"
            min="0"
            max="100"
            value={websiteFilter}
            onChange={(e) => handleRangeChange(e, 'websites')}
            onMouseUp={() => handleRangeApply('websites')}
            onTouchEnd={() => handleRangeApply('websites')}
          />
        </div>
        <Brands />
        <Server />
        <Ssl />
        <Domain />
        <Email />
      </div>
    </aside>
  );
};

export default Sidebar;
