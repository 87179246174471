import React, { useState } from "react";
import logo from "../../assets/logo.svg";
import menu_icon from "../../assets/menu_icon.svg";
import banner_img from "../../assets/banner-img.svg";
import step1 from "../../assets/step1.svg";
import step2 from "../../assets/step2.svg";
import step3 from "../../assets/step3.svg";
import about_us from "../../assets/about_us.svg";
import contact_us from "../../assets/contact_us.svg";
import back_to_top from "../../assets/back_to_top.svg";
import "./Homepage.scss"
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Homepage = () => {
    // const mybutton = document.getElementById("btn-back-to-top");

    // window.onscroll = () => mybutton.style.display = (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) ? "block" : "none";

    // mybutton.addEventListener("click", () => {
    //     document.body.scrollTop = 0;
    //     document.documentElement.scrollTop = 0;
    // });

    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        message: "",
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validate = () => {
        let tempErrors = {};
        if (!formData.fullName) tempErrors.fullName = "Full name is required.";
        if (!formData.email) {
            tempErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = "Email address is invalid.";
        }
        if (!formData.message) tempErrors.message = "Message is required.";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        // Remove the error message for the field being edited
        setErrors({
            ...errors,
            [name]: ""
        });
    };

    const handleSubmit = async (e) => {
        toast.success("Email sent successfully!");
        e.preventDefault();
        if (validate()) {
            setIsSubmitting(true);
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/all/emailSend`, formData);
                toast.success("Email sent successfully!");
                setFormData({
                    fullName: "",
                    email: "",
                    message: "",
                })
            } catch (error) {
                toast.error("There was an error sending the email.");
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return <>
        <main>
            <header className="sticky-top">
                <nav className="navbar navbar-expand-lg border-bottom bg-white" id="navscrollspy">
                    {/* fixed-top add this class if needed  */}
                    <div className="container">
                        <a className="navbar-brand p-0" href="#"><img src={logo} alt="Hosting Grabber" /></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <img src={menu_icon} alt="Hosting Grabber" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <a className="nav-link text-color active me-md-4" aria-current="page" href="#home">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-color me-md-4" href="#process">Process</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-color me-md-4" href="#about_us">About Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-color" href="#contact_us">Contact Us</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
            {/* home  */}
            <div className="content_area" data-bs-spy="scroll" data-bs-target="#navscrollspy" data-bs-offset="0">
                <section id="home">
                    <div className="container">
                        <div className="row py-4 py-md-5 text-center text-md-start">
                            <div className="col-md-7 pt-md-0 pt-lg-4 pb-3">
                                <h1 className="h1_title fs-3 fs-md-6 pe-4 pb-2 text-color">Find the <span className="sub_title fw-bold">optimal
                                    hosting plan</span> for your project with ease.</h1>
                                <p className="fs-6 fs-md-5 lh-base w-md-75 pe-4 pb-2 text-color">Host Grabber offers convenient filters to
                                    assist you in discovering the most budget-friendly and suitable hosting solution for your website.</p>
                                <Link to="/explore"> <button type="button" className="btn w-130 h-48 rounded-5 btn-bg text-white btn_explore">Explore Tool</button></Link>
                            </div>
                            <div className="col-md-5">
                                <div className="banner_img text-center">
                                    <img className="img-fluid" src={banner_img} alt="Hosting Grabber" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Process  */}
                <section id="process" className="text-center text-md-start">
                    <div className="container">
                        <div className="row py-4 py-xl-5">
                            <div className="col-md-12 pb-3">
                                <div className="w-sm-40 w-md-40 m-auto text-center">
                                    <h2 className="fs-3 fs-md-2 text-color">Streamline Your Hosting Search with <span
                                        className="sub_title fw-bold">Host Grabber</span></h2>
                                    <p className="fs-6 fs-md-5 lh-base pb-2 text-color">Discover, Compare, and Connect with the Perfect Hosting
                                        Provider in Just Three Steps.</p>
                                </div>
                            </div>
                        </div>
                        {/* steps 1 */}
                        <div className="row">
                            <div className="col-md-6">
                                <h4 className="lh-base lh-md-lg text-color"><span className="d-block sub_title">Step 1</span>Discover the Perfect
                                    Hosting Plan</h4>
                                <p className="fs-6 lh-base">Use our advanced filtering system to discover hosting plans tailored to your
                                    needs. Filter by price, server space, and more to find the perfect fit for your website.</p>
                            </div>
                            <div className="col-md-6">
                                <div className="setp_1_img">
                                    <img className="img-fluid" src={step1} alt="" />
                                </div>
                            </div>
                        </div>
                        {/* steps 2 */}
                        <div className="row pt-4 flex-column-reverse flex-md-row">
                            <div className="col-md-6">
                                <div className="setp_1_img">
                                    <img className="img-fluid" src={step2} alt="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h4 className="lh-base lh-md-lg text-color"><span className="d-block sub_title">Step 2</span>Explore Provider
                                    Details</h4>
                                <p className="fs-6 lh-base">Explore comprehensive information about each hosting provider, including features,
                                    uptime guarantees, customer support, and user reviews. Make an informed decision by comparing all
                                    aspects of the hosting service before proceeding.</p>
                            </div>
                        </div>

                        {/* steps 3 */}
                        <div className="row pt-4">
                            <div className="col-md-6">
                                <h4 className="lh-base lh-md-lg text-color"><span className="d-block sub_title">Step 3</span>Proceed to Provider's
                                    Website</h4>
                                <p className="fs-6 lh-base">Once you've selected your preferred hosting provider, simply click on the link
                                    provided to visit their website directly. There, you can sign up for your chosen hosting plan and take
                                    the next steps to launch your website.</p>
                            </div>
                            <div className="col-md-6">
                                <div className="setp_1_img">
                                    <img className="img-fluid" src={step3} alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {/* About Us  */}
                <section id="about_us" className="about_us mt-5 text-center text-md-start">
                    <div className="container">
                        <div className="row pt-md-5 pb-md-5">
                            <h2 className="text-color fs-3 fs-md-2 text-center py-4 py-md-5">About <span className="sub_title fw-bold">Host
                                Grabber</span></h2>
                            <div className="col-md-6">
                                <h3 className="text-color fw-semibold fs-4">Streamlining Your Hosting Search</h3>
                                <p>At <span className="sub_title">Host Grabber</span>, we're dedicated to simplifying the process of finding
                                    the perfect hosting solution for your website. With our user-friendly platform, we aim to revolutionize
                                    the way users discover, compare, and connect with hosting providers. Our commitment lies in providing
                                    you with the tools and resources necessary to make informed decisions about your online presence.</p>
                                <h3 className="text-color fw-semibold fs-4 pt-4">Empowering Your Online Journey</h3>
                                <p>Our mission at <span className="sub_title">Host Grabber </span>is to empower individuals and businesses
                                    alike on their online journey. Whether you're a seasoned webmaster or just starting, we're here to guide
                                    you through the complexities of hosting selection. Through advanced filtering options, detailed provider
                                    information, and seamless redirection to your chosen service's website, we ensure a hassle-free
                                    experience every step of the way. Join us at Host Grabber and unlock the potential of your online
                                    presence today.</p>
                            </div>
                            <div className="col-md-6 text-center">
                                <img className="img-fluid" src={about_us} alt="About Us" />
                            </div>
                        </div>
                    </div>
                </section>

                {/* About Us  */}
                <section id="contact_us" className="contact_us mt-4 pb-md-3 text-center text-md-start">
                    <div className="container">
                        <div className="row pb-md-5">
                            <h2 className="text-color fs-3 fs-md-2 text-center py-4 py-md-5">Contact <span className="sub_title fw-bold">Host
                                Grabber</span></h2>
                            <div className="col-md-6 pt-md-4">
                                <h3 className="text-color fw-semibold fs-4">Get in Touch with Us</h3>
                                <p>At <span className="sub_title">Host Grabber</span>, we value your feedback and inquiries. Whether you have
                                    questions about our platform, need assistance with hosting selection, or simply want to share your
                                    experience, we're here to help. Reach out to us through the contact form below, and our dedicated team
                                    will get back to you as soon as possible.</p>
                                <h4 className="text-color fw-semibold fs-4">Contact Us</h4>
                                <ul className="listStyle_none ps-0">
                                    <li><span className="fw-bold text-color">Phone: </span>+91 8923124071</li>
                                    <li><span className="fw-bold text-color">Email: </span>business@ftechiz.com</li>
                                    <li><span className="fw-bold text-color">Address: </span> 3rd Floor, A-12, IT PARK,Sahastradhara Rd, Doon IT
                                        Park, Danda Lakhond, Dehradun,
                                        Uttarakhand, India 248001</li>
                                </ul>
                            </div>
                            <div className="col-md-6 text-center">
                                <img className="img-fluid" src={contact_us} alt="Contact Us" />
                            </div>
                        </div>
                        {/* form  */}
                        <div className="row">
                            <div className="col-md-7 m-auto">
                                <div className="get_in_touch p-4 mb-4 mt-3 mt-md-0 shadow">
                                    <form onSubmit={handleSubmit} method="post">
                                        <div className="row">
                                            <div className="col-md-6 mb-4">
                                                <input
                                                    type="text"
                                                    className="form-control rounded-5 h-48"
                                                    placeholder="Enter full name"
                                                    aria-label="Full name"
                                                    name="fullName"
                                                    value={formData.fullName}
                                                    onChange={handleChange}
                                                />
                                                {errors.fullName && <p className="text-danger position-absolute">{errors.fullName}</p>}
                                            </div>
                                            <div className="col-md-6 mb-4">
                                                <input
                                                    type="email"
                                                    className="form-control rounded-5 h-48"
                                                    placeholder="Enter email"
                                                    aria-label="Email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                                {errors.email && <p className="text-danger position-absolute">{errors.email}</p>}
                                            </div>
                                            <div className="col-12">
                                                <textarea
                                                    className="form-control rounded-5"
                                                    placeholder="Enter your Message"
                                                    rows="3"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                ></textarea>
                                                {errors.message && <p className="text-danger position-absolute">{errors.message}</p>}
                                                <div className="text-center mt-4">
                                                    <button
                                                        type="submit"
                                                        className="btn w-130 h-48 rounded-5 text-white send_btn"
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? "Sending..." : "Send"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <footer className="footer_bg mt-5 pt-3 pb-3 text-center text-md-start">
                <div className="container">
                    <div className="row pt-3">
                        <div className="col-md-6">
                            <p className="text-white">All Rights reserved to Host Grabber</p>
                        </div>
                        <div className="col-md-6 text-md-end">
                            <ul className="footer_link p-0">
                                <a className="text-white pe-3" href="#" data-bs-toggle="modal" data-bs-target="#discloseModal">Disclose</a>
                                <a className="text-white pe-3" href="#" data-bs-toggle="modal" data-bs-target="#termsConditionModal">Terms and condition</a>
                                <a className="text-white" href="#" data-bs-toggle="modal" data-bs-target="#privacyPolicy">Privacy Policy</a>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <button id="btn-back-to-top" className="border-0"><img width="75" src={back_to_top} alt="" /></button>
        </main>
        <ToastContainer />
        {/* disclose Modal  */}
        <div className="modal fade" id="discloseModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Disclose</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>At HostingGrabber, transparency and honesty are core values we uphold in our operations. We believe in
                            providing our users with clear information about our business practices, including how we generate revenue
                            through affiliate partnerships.</p>
                        <p> Affiliate Links: Some of the links on our website are affiliate links, which means that if you click on
                            them
                            and make a purchase, we may receive a commission from the hosting provider. This commission comes at no
                            additional cost to you and helps support the maintenance and operation of our website.
                            Objective Recommendations: Our primary goal is to assist users in finding the best hosting solutions
                            tailored
                            to their needs, including pricing, server size, performance, and features. We strive to present unbiased
                            recommendations based on thorough research and analysis, ensuring that our users receive valuable insights
                            to
                            make informed decisions.</p>
                        <p> Independence and Integrity: While we may earn commissions through affiliate links, it does not influence
                            our
                            recommendations or rankings. We maintain editorial independence and integrity in our content, prioritizing
                            the
                            interests of our users above all else. Our reviews and recommendations are based solely on the merits of the
                            hosting providers and their suitability for our users' requirements.</p>
                        <p> Full Disclosure: We are committed to full transparency regarding our affiliate relationships. Whenever a
                            link
                            is an affiliate link, we will clearly disclose it to our users, either within the content or through a
                            disclaimer on the website. We believe in fostering trust and credibility with our audience by providing
                            complete and upfront information about our business practices.</p>
                        <p> Your trust is of utmost importance to us, and we are dedicated to upholding the highest standards of
                            transparency, integrity, and honesty in all aspects of our operations.</p>
                    </div>
                </div>
            </div>
        </div>

        {/* Terms and condition Modal  */}
        <div className="modal fade" id="termsConditionModal" tabIndex="-2" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Terms and condition</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        Welcome to HostingGrabber! By accessing and using our website, you agree to comply with these terms and
                        conditions. Please read them carefully.

                        <ul className="listStyle_none mt-3 ps-0">
                            <li><b>1. Use of Website:</b> HostingGrabber provides information and recommendations on hosting services.
                                You may use this information for personal or business purposes, but you may not reproduce, distribute, or
                                modify our content without permission.</li>
                            <li><b>2. Accuracy of Information:</b>While we strive to provide accurate and up-to-date information, we
                                cannot guarantee the accuracy, completeness, or reliability of the content on our website. Users are
                                responsible for verifying the information before making any decisions or taking action based on it.</li>
                            <li><b>3. Third-Party Links: </b>HostingGrabber may contain links to third-party websites or resources. We
                                are not responsible for the content or reliability of any third-party websites linked to from our site.
                                Use of any third-party websites is at your own risk.</li>
                            <li><b>4. Limitation of Liability: </b>HostingGrabber and its affiliates shall not be liable for any direct,
                                indirect, incidental, special, or consequential damages arising out of or in any way connected with the
                                use of our website or the information provided on it.</li>
                            <li><b>5. Changes to Terms:</b>HostingGrabber reserves the right to modify or revise these terms and
                                conditions at any time without prior notice. Your continued use of the website after any changes signifies
                                your acceptance of the updated terms.

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        {/* Privacy Policy  */}
        <div className="modal fade" id="privacyPolicy" tabIndex="-2" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Privacy Policy</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">At HostingGrabber, we are committed to protecting your privacy. This Privacy Policy
                        outlines how we collect, use, and disclose personal information when you visit our website.

                        <ul className="listStyle_none mt-3 ps-0">
                            <li><b>1. Information We Collect: </b>We do not collect any personally identifiable information from
                                visitors to our website. We may collect non-personally identifiable information such as browser type,
                                operating system, and pages visited for analytics purposes.</li>
                            <li><b>2. Use of Cookies: </b>HostingGrabber uses cookies to improve your browsing experience and provide
                                personalized content. You can disable cookies in your browser settings if you prefer not to receive them.
                            </li>
                            <li><b>3. Third-Party Services:</b>We may use third-party services, such as Google Analytics, to track
                                website usage and analyse trends. These third-party services may collect information about your visit to
                                our website.</li>
                            <li><b>4. Disclosure of Information: </b>We do not sell, trade, or otherwise transfer your personal
                                information to third parties. However, non-personally identifiable information may be provided to trusted
                                third parties for marketing, advertising, or other purposes.</li>
                            <li><b>5. Data Security: </b>HostingGrabber takes reasonable measures to protect against unauthorized
                                access, use, alteration, or destruction of personal information stored on our servers.</li>
                            <li><b>6. Changes to Privacy Policy: </b>We reserve the right to modify or update this Privacy Policy at any
                                time. Any changes will be posted on this page, and your continued use of the website after the changes are
                                made constitutes acceptance of the updated policy.</li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </>
}